import { graphql, useStaticQuery } from "gatsby"
import React, { useMemo } from "react"
import ReviewContainer from "./ReviewContainer"

const ReviewSection = () => {
  const prismicReviewSectionData = useStaticQuery(graphql`
    query MyReviewSectionQuery {
      prismicHome {
        data {
          testimony1
          testimony2
          testimony3
          testimonyby1
          testimonyby2
          testimonyby3
        }
      }
      imageSharp(fluid: { originalName: { eq: "plan-bg-black.png" } }) {
        gatsbyImageData
      }
    }
  `)

  const reviewSectionData = useMemo(() => {
    let data = []
    if (prismicReviewSectionData) {
      data.push({
        testimony: prismicReviewSectionData.prismicHome.data.testimony1,
        by: prismicReviewSectionData.prismicHome.data.testimonyby1,
      })
      data.push({
        testimony: prismicReviewSectionData.prismicHome.data.testimony2,
        by: prismicReviewSectionData.prismicHome.data.testimonyby2,
      })
      data.push({
        testimony: prismicReviewSectionData.prismicHome.data.testimony3,
        by: prismicReviewSectionData.prismicHome.data.testimonyby3,
      })
    }
    return data
  }, [prismicReviewSectionData])

  return (
    <div
      className="relative flex flex-col bg-site-dark-gray items-center justify-center w-full pt-4 lg:pt-16 pb-12 bg-50 lg:bg-20"
      style={{
        backgroundImage: `url('${prismicReviewSectionData.imageSharp.gatsbyImageData?.images.fallback.src}')`,
        backgroundPosition: "right bottom",
        backgroundRepeat: "no-repeat",
        //backgroundSize: "20%",
        filter: "darken",
      }}
    >
      <div className="flex sm:flex-row flex-col text-white max-w-7xl ">
        {reviewSectionData.map((review, index) => {
          return (
            <ReviewContainer
              key={`review-container-${index}`}
              testimony={review.testimony}
              by={review.by}
            />
          )
        })}
      </div>
      {/* <div
        className="absolute bg-red-500 px-4 pt-20 pb-20 h-full"
        style={{
          backgroundImage: `url('${prismicReviewSectionData.imageSharp.gatsbyImageData.images.fallback.src}')`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          filter: "darken",
        }}
      >
        &nbsp;
      </div> */}
    </div>
  )
}

export default ReviewSection
