import React, { useState } from "react"
import "./review-section-styles.css"

const ReviewContainer = ({ testimony, by }) => {
  const [expand, setExpand] = useState(false)

  return (
    <div className="flex gap-x-4 flex-col w-full lg:w-1/3 px-8 review-container lg:pt-4 mt-16 lg:mt-0">
      <div
        className="review-container-wrapper mt-4"
      >
        <div
          style={
            expand
              ? {}
              : {
                  overflow: "hidden",
                  display: "-webkit-box",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 5,
                }
          }
        >
          {testimony}
        </div>
      </div>
      <div
        className="text-site-yellow pb-4 lg:pb-8 cursor-pointer pt-1"
        onClick={() => setExpand(!expand)}
      >
        {`Read ${expand ? "less" : "more"}`}
      </div>
      <div className="text-gray-500 text-sm ">{by}</div>
    </div>
  )
}
export default ReviewContainer
