import React, { useMemo } from "react"
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { useStaticQuery, graphql } from "gatsby"

import { BsChevronDown } from "react-icons/bs"
import useWindowSize from "../../utils/useWindowSize"

// Import Swiper styles
import "swiper/css"

// import required modules
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay } from "swiper/modules"

const HomeHeroSectionUpdate = () => {
  const [prefersReducedMotion, setPrefersReducedMotion] = React.useState(false)
  const screen = useWindowSize()
  const imagesData = useStaticQuery(graphql`
    query HeroImages {
      prismicHome {
        data {
          images {
            position
            image {
              fluid {
                src
              }
              gatsbyImageData(width: 1920, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)

  const images = useMemo(() => {
    const imageFiles = []
    if (imagesData) {
      for (const file of imagesData.prismicHome.data.images) {
        imageFiles.push({ image: file.image, position: file.position })
      }
    }

    return imageFiles
  }, [imagesData])

  React.useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)")

    const handleChange = event => {
      setPrefersReducedMotion(event.matches)
    }

    handleChange(mediaQuery)

    mediaQuery.addEventListener("change", handleChange)

    return () => {
      mediaQuery.removeEventListener("change", handleChange)
    }
  }, [])

  if (prefersReducedMotion) {
    return (
      <div>
        <StaticImage
          style={{
            objectFit: "cover",
            height: "90vh",
          }}
          // This is a presentational image, so the alt should be an empty string
          alt="First Home Hero Background"
          src={"../../images/backgrounds/b_humphries-0735-07-entry.jpg"}
          formats={["auto", "webp", "avif"]}
          width={1920}
        />
      </div>
    )
  }

  return (
    <div id="home" className="block">
      <h1
        className="absolute top-24 left-4 xl:left-12 uppercase z-20 text-site-yellow text-4xl tracking-wider font-medium font-century-gothic"
        style={{ textShadow: "0px 0px 20px black" }}
      >
        Designing Private Homes
      </h1>
      <Swiper
        slidesPerView={1}
        loop={true}
        modules={[Autoplay]}
        autoplay={{
          delay: 3000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        speed={1000}
      >
        {images.map((image, index) => {
          const img = getImage(image.image)
          return (
            <SwiperSlide>
              <div
                style={{
                  width: "100%",
                  height: "90vh",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <StaticImage
                  id="cover"
                  src="../../images/blank.png"
                  quality={95}
                  placeholder="DOMINANT_COLOR"
                  formats={["AUTO", "WEBP"]}
                  alt={`hero${index}`}
                  className="w-full h-full z-5 top-0 left-0"
                  style={{position: "absolute"}}
                  objectFit="cover"
                />
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    position: "relative",
                  }}
                >
                  <GatsbyImage
                    image={img}
                    objectPosition={image.position ? image.position : "center"}
                  />
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div
        className="absolute bottom-0 z-10"
        style={{ left: "50%", transform: "translateX(-50%)" }}
      >
        <BsChevronDown
          className="text-7xl text-site-yellow cursor-pointer"
          style={{ filter: "drop-shadow(2px 2px 10px black)" }}
          onClick={() => {
            window.scrollTo({ top: screen.height - 50 })
          }}
        />
      </div>
    </div>
  )
}

export default HomeHeroSectionUpdate
