import React from "react"

import Layout from "../components/layout/layout"
import Seonew from "../components/seo-new"
import HomeHeroSectionUpdate from "../components/sections/home-hero-section-update"
import HomeAboutSectionNew from "../components/sections/home-about-section-new"
import CommitmentSignature from "../components/sections/CommitmentSignature"
import ReviewSection from "../components/sections/ReviewSection"
import HomeContactSectionNew from "../components/sections/ContactSection/home-contact-section-new"
import HighlightSection from "../components/sections/HighlightsSection"
import HomeSpecialtySection from "../components/sections/HomeSpecialtySection"

const IndexPage = () => (
  <Layout isHome={true}>
    <Seonew />
    <HomeHeroSectionUpdate />
    <HighlightSection />
    <HomeAboutSectionNew />
    <HomeSpecialtySection />
    <ReviewSection />
    <HomeContactSectionNew />
    <CommitmentSignature />
  </Layout>
)

export default IndexPage
