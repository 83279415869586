import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import YoutubePlayerLite from "../common/youtube-player-lite"
import { PrismicRichText } from "@prismicio/react"
import htmlSerializer from "../../utils/htmlSerializer"

const StyledH1 = styled.h1`
  line-height: 1.1;
`

const HomeAboutSectionNew = () => {
  const homeVideoAbout = useStaticQuery(graphql`
    query homeVideoAbout {
      prismicHome {
        data {
          title
          youtube_id
          content {
            html
            richText
          }
          additional_content {
            html
            richText
          }
        }
      }
    }
  `)

  const {
    title,
    youtube_id,
    content,
    additional_content,
  } = homeVideoAbout.prismicHome.data
  return (
    <div id="intro" className="relative bg-site-gray w-full flex items-center justify-center">
      <div className="w-full max-w-7xl py-12 md:py-16 px-4">
        <div className="flex items-center justify-center">
          <StyledH1 className="font-display text-2xl xl:text-3xl text-center xl:text-left text-site-yellow mb-6 tracking-wider">
            {title}
          </StyledH1>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-20">
          <div className="w-full mt-4 z-20">
            <YoutubePlayerLite youtubeID={youtube_id} />
          </div>
          <div id="compgoal" className="about-text w-full flex flex-col pt-4 pr-0 xl:pr-10">
            <PrismicRichText
              field={content.richText}
              components={htmlSerializer}
            />
          </div>
          <div>&nbsp;</div>
          <div className="w-full flex items-center justify-center">
            <Link
              to="/about-us-overview"
              className="bg-site-yellow text-white text-center uppercase w-full max-w-sm py-2 rounded-md"
            >
              Discover More
            </Link>
          </div>
        </div>
        <div className="about-text w-full flex flex-col pt-8 pr-0 xl:pr-10">
          <PrismicRichText
            field={additional_content.richText}
            components={htmlSerializer}
          />
        </div>
      </div>
    </div>
  )
}

export default HomeAboutSectionNew
