import { graphql, useStaticQuery } from "gatsby"
import React, { useMemo } from "react"

const HighlightDiv = ({ children }) => {
  return (
    <div
      className="h-full border border-site-yellow flex flex-col gap-4 items-center justify-start rounded-lg shadow p-8"
      style={{ boxShadow: "0px 0px 10px 1px rgba(232, 176, 63, 50)" }}
    >
      {children}
    </div>
  )
}

const HighlightSection = () => {
  const prismicHighlightSectionData = useStaticQuery(graphql`
    query HighlightSectionQuery {
      prismicHome {
        data {
          highlights {
            attribute
            details
          }
        }
      }
    }
  `)

  const attributes = useMemo(() => {
    if (prismicHighlightSectionData) {
      return prismicHighlightSectionData.prismicHome.data.highlights.map(highlight => {
        return {
          title: highlight.attribute,
          details: highlight.details,
        }
      })
    }
    return []
  }, [prismicHighlightSectionData])

  return (
    <div className="w-full flex bg-site-gray items-center justify-center">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-16 w-full max-w-5xl text-white py-12 px-4">
        {attributes.map((attribute, idx) => {
          return (
            <HighlightDiv key={`highlight-${attribute.title}-${idx}`}>
              <span className="text-site-yellow font-medium text-2xl">
                {attribute.title}
              </span>
              <span>{attribute.details}</span>
            </HighlightDiv>
          )
        })}
        {/* <HighlightDiv>
          <span className="text-site-yellow font-medium text-2xl">
            Innovative
          </span>
          <span>
            Out designs are as unique as our clients and we create their unique
            sites to enhance the quality of their lives
          </span>
        </HighlightDiv>
        <HighlightDiv>
          <span className="text-site-yellow font-medium text-2xl">
            Experienced
          </span>
          <span>
            With over 40 years of teamwork, our professionals understand how to
            best achieve our client's goals with government agencies and
            builders
          </span>
        </HighlightDiv>
        <HighlightDiv>
          <span className="text-site-yellow font-medium text-2xl">
            Dedicated
          </span>
          <span>
            We work to exceed our client's expectations in all that we do - we
            are our client's guide through the process to a successful
            completion.
          </span>
        </HighlightDiv> */}
      </div>
    </div>
  )
}

export default HighlightSection
